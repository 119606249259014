import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../../components/social.js"
import CON_LIV from "../../images/countryliving.jpg"
import Layout from "../../components/LayoutAlt.js"
import { FaTree, FaArrowAltCircleLeft } from "react-icons/fa"
import ReactMarkdown from "react-markdown"



export default function CountryLiving ({ data }) {
    return( 
        <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Living in the country is God's will for our lives." />
        <title>ReaCH | Country Living</title>
        <link rel="canonical" href="https://reachun.org/lifestyles/country" />
      </Helmet>

        <section className="sm:w-3/4 sm:ml-1/8 xs:mt-10 sm:mt-20 mb-20">
        <div className="font-playfairdisplay  text-blue-alt">
            <div className="flex items-center space-x-4 xs:px-4 sm:px-0">
            <img src={CON_LIV} alt="Country Living" className="xs:w-32 xs:h-32 sm:w-48 sm:h-48 rounded-full border-8 border-gray-100"/>
            <h1 className="xs:text-4xl sm:text-6xl md:text-7xl">Country Living</h1>
            </div>
           <Link to="/lifestyles">
           <section className="font-opensans  text-white  bg-blue-alt xs:mt-12 sm:mt-20 lg:mt-5 xl:mt-10 py-2 pl-4 rounded-r-xl flex items-center">      <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer"/> 
                    <p className=" text-xs sm:text-base">/lifestyles/country</p>
                  </section>
            </Link>
           </div>
  
           <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap:2 xl:gap-8 mt-10 xs:px-4 sm:px-0">
           {data.allStrapiLifestyles.edges.map(con =>(
                <div key={con.node.id} className="border-b border-gray-300 w-64 px-2 ">
                <div className="h-96">
                
                <p className="font-montserrat font-bold text-blue-alt py-5 ">{con.node.title}</p>
                <div><img src={con.node.image.url} alt="family" className="object-cover w-full h-32" /></div>
                <p className="font-opensans articles"><ReactMarkdown children={con.node.desc} /></p>
                </div>
                <Link to={`/lifestyles/${con.node.category}/${con.node.slug}`}>
                <p className="my-5 text-blue-alt hover:text-white hover:bg-blue-alt border-2 border-blue-alt shadow-xl flex items-center justify-center rounded-lg h-12 w-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                <FaTree size="32"/>
                </p>
                </Link>
               
                </div>
                ))}
             </div>
           
        </section>
        <Social />
        </Layout>   
    )

}

export const query = graphql`
  query {
    allStrapiLifestyles(filter: {category: {eq: "country"}}) {
      edges {
        node {
          desc
          slug
          title
          image {
                url
          }
          id
          date
          content
          author {
            avatar {
              url
            }
            name
          }
          category
        }
      }
    }
  }
`